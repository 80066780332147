<template>
    <div class="page_msg container">
      <div class="header_gruop page_width flex_sb">
        <div class="icon_home flex_center"></div>
        <div class="header_title">pay success </div>
        <div></div>
      </div>
  
      <div class="scoller grid_content" :v-infinite-scroll="false" style="overflow:auto;-webkit-overflow-scrolling: touch">
          <div class="service page_width flex_col_center">
            <div class="icon_cont">
                <svg t="1730978867822" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8184" width="128" height="128"><path d="M512 0c-282.453333 0-512 229.546667-512 512s229.546667 512 512 512 512-229.546667 512-512-229.546667-512-512-512z m324.266667 355.84l-384 384.853333c-10.24 10.24-23.04 14.506667-35.84 14.506667s-26.453333-5.12-35.84-14.506667L187.733333 547.84c-19.626667-19.626667-19.626667-52.053333 0-72.533333 19.626667-19.626667 52.053333-19.626667 72.533334 0L416.426667 631.466667l348.16-348.16c19.626667-19.626667 52.053333-19.626667 72.533333 0 19.626667 20.48 19.626667 52.906667-0.853333 72.533333z" fill="#11CD6E" p-id="8185"></path></svg>
            </div>
            <p style="margin-top:8px;text-align: center;">Order payment successful, will return to the order page in <span>{{seconds}}</span> seconds</p>
            
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data(){
        return {
            seconds:5
        }
    },
      methods:{
          toBack(){
              this.$router.back()
          }
      },
      created(){
        let _this = this
        this.timer = setInterval(()=>{
            _this.seconds--
            console.log(' _this.seconds', _this.seconds)
            if(_this.seconds == 0){
                _this.$router.push('/order')
            }
        },1000)
      },
      
    beforeDestroy() {
        clearInterval(this.timer);        
        this.timer = null;
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .icon_cont{
    margin-top:80px;
  }
  
  </style> 